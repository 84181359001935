/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-prototype-builtins */
import axios from 'axios';
import { defaultDirection } from '../constants/defaultValues';

let url = '';
if (process.env.NODE_ENV === 'development') {
  // url = 'http://fe4b-49-204-161-122.ngrok.io/api/';
  url = 'http://localhost:5750/api/';
} else {
  url = 'https://api.folkus.jp/api/';
}

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};
export const lbirul = () => {
  const retruinstr = url.replace('/api/', '');
  return retruinstr;
};
export const Api = axios.create({
  baseURL: url,
});

export const LoginWithPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    Api.post(`user/login/admin`, { email, password })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject(0);
      });
  });
};
export const LogedinUserData = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);
      console.log(AuthStr);

      Api.get(`user/me`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllParentData = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);
      console.log(AuthStr);

      Api.get(`category/getparent`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const LogedinUserNotification = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      console.log(AuthStr);

      Api.get(`notification/read/admin/count`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const TotalCommissionRevinew = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      console.log(AuthStr);

      Api.get(`order/get/revenue`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllNotification = (pageno, pagecount) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`notification/get/${pageno}/${pagecount}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UpdateReadNotification = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`notification/read/admin`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllPayouts = (pageno, pagecount, from, to, sallerId) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/all/saller/payouts/${pageno}/${pagecount}`,
        { from_date: from, to_date: to, seller_id: sallerId },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UploadFileToImport = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `product/file/upload`,
        { file: data },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UploadFileImages = (data, name) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `product/bulk/image/upload`,
        { product_pic_url: data, file_name: name },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllDonePayouts = (pageno, pagecount, from, to, sallerId) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/payouts/${pageno}/${pagecount}`,
        { from_date: from, to_date: to, seller_id: sallerId },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const GetAllCategroy = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `category/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UpdatePayouts = (Data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`payout/saller`, Data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllParantCategroy = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`category/getall/active`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetCategoryEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`category/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const SaveCategory = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`category/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateCategory = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`category/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteCategory = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`category/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteProduct = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`product/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteUser = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`user/delete/by/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionUser = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`user/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionProduct = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`product/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionCountry = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`country/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionCity = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`city/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionBrand = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`brand/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionQuestion = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`question/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionSize = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`size/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionAds = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`ad/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionBread = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`addon/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const StatusActionCategory = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`category/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const GetAllBread = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `addon/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllAdminUsers = (pageno, pagecount) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`user/get/admin/list/${pageno}/${pagecount}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllBrand = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `brand/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllQuestion = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `question/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllAds = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `ad/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllPlan = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `plan/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllSize = (pageno, pagecount) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`size/getall/${pageno}/${pagecount}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllCountry = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `country/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllCity = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `city/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllCountryDropDown = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`country/getall/active`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllCityDropDown = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`city/getall/active`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const DeleteBread = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`breedtype/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteBrand = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`brand/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteSize = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`size/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteCurrency = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`currency/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateBread = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`addon/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateCity = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`city/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateAdminUsers = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`user/update/admin/user`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateSize = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`size/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateAds = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`ad/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdatePlan = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`plan/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateBrand = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`brand/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateQuestion = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`question/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateCountry = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`country/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateSetting = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`setting/add/parameter`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateInventorySetting = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`setting/add/parameter/stock`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateCompanySetting = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`setting/add/parameter/companydetail`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const GetBreadEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`addon/getbyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetCityEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`city/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAdminUserEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`user/get/admin/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetBrandEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`brand/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetQuesionEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`question/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetSizeEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`size/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAdsEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`ad/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetPlanEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`plan/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetCountryEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`country/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const GetSettingEdit = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`setting/get/setting/data`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const SaveAdminUser = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`user/create/admin/user`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveBread = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`addon/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveCity = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`city/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveBrand = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`brand/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveQuestion = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`question/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveSize = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`size/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveAds = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`ad/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SavePlan = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`plan/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveCountry = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`country/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const GetAllProduct = (pageno, pagecount, from, to, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `product/getall/${pageno}/${pagecount}`,
        {
          from_date: from,
          to_date: to,
          filter_name: filter,
          filter_value: value,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const GetProductEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`product/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllActiveBrand = () => {
  return new Promise((resolve, reject) => {
    Api.get(`brand/getall/active`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};
export const GetAllNotParantCategoryBrand = () => {
  return new Promise((resolve, reject) => {
    Api.get(`category/get/all/active/child`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};
export const GetAllSizeActive = () => {
  return new Promise((resolve, reject) => {
    Api.get(`size/getall/active`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};

export const GetAllUsers = (
  pageno,
  pagecount,
  from,
  to,
  filter,
  value,
  gender,
  deletefilter
) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      // /
      Api.post(
        `user/admin/all/user/${pageno}/${pagecount}`,
        {
          from_date: from,
          to_date: to,
          filter_name: filter,
          filter_value: value,
          gender,
          deletefilter,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UploadProductImages = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      // /
      Api.post(
        `product/image/add`,
        { product_pic_url: data },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ExportUsers = (from, to, filter, value, gender, deletefilter) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);

      Api.post(
        `user/export`,
        {
          from_date: from,
          to_date: to,
          filter_name: filter,
          filter_value: value,
          gender,
          deletefilter,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ExportProduct = (from, to, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);

      Api.post(
        `product/export`,
        {
          from_date: from,
          to_date: to,
          filter_name: filter,
          filter_value: value,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ExportOrder = (from, to, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `order/get/admin/all/csv`,
        {
          from_date: from,
          to_date: to,
          filter_name: filter,
          filter_value: value,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ExportPayouts = (from, to, sallerId) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/all/saller/payouts/csv`,
        { from_date: from, to_date: to, sallerId },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ExportPayoutsDone = (from, to, sallerId) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/payouts/csv`,
        { from_date: from, to_date: to, sallerId },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const ExportSellerSales = (from, to, sallerId, filtervalue) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/saller/sales/csv`,
        {
          from_date: from,
          to_date: to,
          sallerId,
          filter_value: filtervalue,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ImportSubmit = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `product/file/data/insert`,
        { importdata: data },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const ExportCommissionSales = (from, to, sallerId) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/saller/sales/csv`,
        { from_date: from, to_date: to, sallerId },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const DownloadOrderInvocie = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`order/get/order/invoice/pdf/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};

export const GetUsersEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`user/admin/getbyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetUsersStripeStatus = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`user/get/admin/stripe/account/status/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UpdateUser = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`user/admin/user/update`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const CreateUser = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`user/admin/user/add`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const CreateProduct = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`product/admin/add`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const forgotPassword = () => {
  return new Promise((resolve, reject) => {
    Api.post(`user/admin/forgot/password`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject(0);
      });
  });
};
export const DashBoardData = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`order/get/deshbord`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const forgotUpdate = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`user/admin/forgot/password/update`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const PasswordUpdate = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`user/admin/update/password`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const UpdateProduct = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`product/admin/product/update`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const GetAllActiveColor = () => {
  return new Promise((resolve, reject) => {
    Api.get(`color/getall/active`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};
export const GetAllActiveCurrency = () => {
  return new Promise((resolve, reject) => {
    Api.get(`currency/getall/active`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};
export const GetAllOrder = (pageno, pagecount, from, to, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `order/get/admin/all/${pageno}/${pagecount}`,
        {
          from_date: from,
          to_date: to,
          filter_name: filter,
          filter_value: value,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllSallerSales = (
  pageno,
  pagecount,
  from,
  to,
  sallerId,
  filtervalue
) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/saller/sales/${pageno}/${pagecount}`,
        {
          from_date: from,
          to_date: to,
          seller_id: sallerId,
          filter_value: filtervalue,
        },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllCategroySales = (pageno, pagecount, from, to, sallerId) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `payout/get/categroy/sales/${pageno}/${pagecount}`,
        { from_date: from, to_date: to, category_id: sallerId },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllSaller = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.get(`user/get/seller/list`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetOrderEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`order/get/admin/Orderdetails/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UpdateOrder = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`order/update/admin`, data, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const SaveStory = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`story/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const GetAllStory = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `story/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const StatusActionStory = (id, status) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`story/update/status/${id}/${status}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const DeleteStory = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.delete(`story/deletebyid/${id}`, {
        headers: { Authorization: AuthStr },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};

export const GetStoryEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`story/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UpdateStory = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`story/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};

export const GetAllPlanCategory = (pageno, pagecount, filter, value) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(
        `plancategory/getall/${pageno}/${pagecount}`,
        { filter_name: filter, filter_value: value },
        { headers: { Authorization: AuthStr } }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const GetAllActivePlan = () => {
  return new Promise((resolve, reject) => {
    Api.get(`plan/getall/active`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};
export const SavePlanCategroy = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.post(`plancategory/add`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};
export const GetCategroyPlanEdit = (id) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');

      const AuthStr = 'Bearer '.concat(tocken);

      Api.get(`plancategory/getbyid/${id}`, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    } else {
      reject();
    }
  });
};
export const UpdatePlanCategory = (data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.hasOwnProperty('user_id')) {
      const tocken = localStorage.getItem('user_id');
      const AuthStr = 'Bearer '.concat(tocken);
      Api.patch(`plancategory/edit`, data, { headers: { Authorization: AuthStr } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          reject(0);
        });
    } else {
      reject();
    }
  });
};